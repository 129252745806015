import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { MediaType } from '../commonTypes';
import { ChangedMFrameParams } from './duck/mFrameTypes';
import * as Selectors from './duck/selectors';
import { fetchMediaInfo, updateMedia } from './duck/thunks';
import { EditorState, Media } from './duck/types';
import { MediaEditor, MediaEditorActions, MediaEditorProps } from './mediaEditor';

export interface MediaEditorContainerProps {
  mediaId: number;
  mediaType: MediaType;
  onCancel: () => void;
}

const mapStateToProps: MapStateToProps<
  MediaEditorProps,
  MediaEditorContainerProps,
  { editor: EditorState }
> = (state, ownProps) => {
  const media = Selectors.selectMedia(state);
  return {
    mediaId: ownProps.mediaId,
    mediaType: ownProps.mediaType,
    onCancel: ownProps.onCancel,
    media,
    fetchState: Selectors.selectMediaFetchState(state),
    saveState: Selectors.selectMediaSaveState(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<MediaEditorActions, any> = (
  dispatch,
  _
) => {
  return {
    fetchMediaInfo: (mediaId: number, mediaType: MediaType) => dispatch<any>(fetchMediaInfo(mediaId, mediaType)),
    updateMedia: (media: Media, changedFields: ChangedMFrameParams[]) => dispatch<any>(updateMedia(media, changedFields))
  };
};

export const MediaEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaEditor);
