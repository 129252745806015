import React, { useCallback, useRef, useState } from 'react';

import {
    Button, Divider, FormLabel, IconButton, InputAdornment, List, ListItem, ListItemSecondaryAction,
    ListItemText, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { useStyles } from './mediaFields.jss';

export interface ArrayFieldProps {
  value: string[];
  label: string;
  onChange: (newValue: string[]) => void;
}

export const ArrayField: React.FunctionComponent<ArrayFieldProps> = props => {
  const classes = useStyles({});
  const { value, onChange, label } = props;
  const [isNewItemEnabled, setNewItemEnabled] = useState(false);
  const onDelete = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    e => {
      if (props.onChange) {
        const indexToRemove = Number(
          e.currentTarget.getAttribute('data-index')
        );
        if (indexToRemove !== undefined) {
          let newArray = [...props.value];
          newArray.splice(indexToRemove, 1);
          props.onChange(newArray);
        }
      }
    },
    [value, onChange]
  );
  const onNewItem = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    _ => setNewItemEnabled(true),
    []
  );
  const onNewItemCancel = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(_ => setNewItemEnabled(false), []);
  const onNewItemSubmit = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    _ => {
      if (newItemInputRef.current && props.onChange) {
        const newItemValue = newItemInputRef.current.value;
        const newArray = [...props.value];
        newArray.splice(0, 0, newItemValue);
        props.onChange(newArray);
      }
      setNewItemEnabled(false);
    },
    [props.value, props.onChange]
  );
  const newItemInputRef = useRef<HTMLInputElement>();
  return (
    <List dense={true}>
      {isNewItemEnabled ? (
        <TextField
          fullWidth
          autoFocus
          inputRef={newItemInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={onNewItemCancel}>CANCEL</Button>
                <Button
                  onClick={onNewItemSubmit}
                  color="secondary"
                  variant="contained"
                >
                  ADD
                </Button>
              </InputAdornment>
            )
          }}
        />
      ) : (
        <ListItem disableGutters={true}>
          <ListItemText className={classes.listItem}>
            <FormLabel>{label}</FormLabel>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton onClick={onNewItem} edge="end">
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      <Divider />

      {props.value.map((v, index) => (
        <ListItem key={v} disableGutters={true}>
          <ListItemText primary={v} className={classes.listItem}/>
          {!isNewItemEnabled && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                data-index={index}
                aria-label="delete"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};
