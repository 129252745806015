import React from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import { MFrameComponentBoolParam } from '../duck/mFrameTypes';
import { CustomFieldProps } from './mediaFields';

export const BoolField: React.FunctionComponent<CustomFieldProps<
  MFrameComponentBoolParam
>> = props => {
  return (
    <FormControlLabel
      className={props.className}
      control={
        <Switch
          checked={props.value}
          onChange={props.onChange}
          name={props.name}
        />
      }
      label={props.mFrameComponentParam.label['en-US'].value}
    />
  );
};
