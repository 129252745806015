import React from 'react';

import { Paper, TextField } from '@material-ui/core';

import { MFrameComponentColorParam } from '../duck/mFrameTypes';
import { CustomFieldProps } from './mediaFields';

export const ColorField: React.FunctionComponent<CustomFieldProps<
  MFrameComponentColorParam
>> = props => {
  return (
    <TextField
      fullWidth
      className={props.className}
      label={props.mFrameComponentParam.label['en-US'].value}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      InputProps={{
        endAdornment: (
          <Paper
          square
            style={{
              width: 24,
              height: 24,
              padding: 2,
              marginBottom: 4
            }}
          >
            <div style={{backgroundColor: props.value, height: '100%'}}/>
          </Paper>
        )
      }}
    />
  );
};
