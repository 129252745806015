export interface MVisionInterface {
    open: (rootNode: string, workgroupId: number, workgroupName: string, userId: number, homeFn: () => void) => void;
    unmount: (rootNode: string) => void;
}

export const MVisionData = {
    workgroupId: 2943,
    workgroupName: 'RaduF',
    userId: 2500,
    backFn: () => console.log("HOME pressed")
}