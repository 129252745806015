import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
  
const styles = (theme: Theme) => {
  return createStyles({
    container: { 
      padding: theme.spacing(2),
      margin: theme.spacing(1)
    },
    form: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    weekdaysButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    weekendButton: {
      marginRight: theme.spacing(1)
    }
  });
};

export const useStyles = makeStyles(styles);
