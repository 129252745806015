import { createAction } from 'typesafe-actions';

import { MediaItem } from '../../commonTypes';
import { ActionTypes } from './types';

export const mediaLibraryFetchRequest = createAction(
    ActionTypes.MEDIA_LIBRARY_REQUEST,
    action => (workgroupId: number) => action({ workgroupId })
  );
  
  export const mediaLibraryFetchSuccess = createAction(
    ActionTypes.MEDIA_LIBRARY_SUCCESS,
    action => (workgroupId: number, items: MediaItem[]) => action({ workgroupId, items })
  );
  
  export const mediaLibraryFetchError = createAction(
    ActionTypes.MEDIA_LIBRARY_ERROR,
    action => (workgroupId: number, err: string) => action({ workgroupId, err })
  );