import produce from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, EditorState } from './types';

type EditorActions = ActionType<typeof Actions>;

const initialState: EditorState = {
  mediaInfo: undefined,
  media: undefined,
  mediaFetchState: {
    fetchCompleted: false,
    fetchError: '',
    id: 0,
    isFetching: false
  },
  mediaSaveState: {
    fetchCompleted: false,
    fetchError: '',
    id: 0,
    isFetching: false
  }
};

export const editorReducer: Reducer<EditorState, EditorActions> = (
  state = initialState,
  action
) =>
  produce(state, draftState => {
    switch (action.type) {
      case ActionTypes.MEDIA_INFO_REQUEST: {
        draftState.mediaFetchState.fetchCompleted = false;
        draftState.mediaFetchState.fetchError = '';
        draftState.mediaFetchState.id = action.payload.mediaId;
        draftState.mediaFetchState.isFetching = true;
        break;
      }
      case ActionTypes.MEDIA_INFO_SUCCESS: {
        draftState.mediaFetchState.fetchCompleted = true;
        draftState.mediaFetchState.fetchError = '';
        draftState.mediaFetchState.isFetching = false;
        draftState.mediaFetchState.id = 0;
        draftState.mediaInfo = action.payload.mediaInfo;
        draftState.media = action.payload.media;
        break;
      }
      case ActionTypes.MEDIA_INFO_ERROR: {
        draftState.mediaFetchState.fetchCompleted = true;
        draftState.mediaFetchState.fetchError = action.payload.error;
        draftState.mediaFetchState.isFetching = false;
        draftState.mediaFetchState.id = 0;
        break;
      }
      case ActionTypes.MEDIA_UPDATE_REQUEST: {
        draftState.mediaSaveState.fetchCompleted = false;
        draftState.mediaSaveState.fetchError = '';
        draftState.mediaSaveState.id = action.payload.media.id;
        draftState.mediaSaveState.isFetching = true;
        break;
      }
      case ActionTypes.MEDIA_UPDATE_SUCCESS: {
        draftState.mediaSaveState.fetchCompleted = true;
        draftState.mediaSaveState.fetchError = '';
        draftState.mediaSaveState.id = 0;
        draftState.mediaSaveState.isFetching = false;
        break;
      }
      case ActionTypes.MEDIA_UPDATE_ERROR: {
        draftState.mediaSaveState.fetchCompleted = true;
        draftState.mediaSaveState.fetchError = action.payload.error;
        draftState.mediaSaveState.id = 0;
        draftState.mediaSaveState.isFetching = false;
        break;
      }
    }
  });
