import React from 'react';

import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './mediaBrowserDialog.jss';
import { MediaLibraryBrowser, MediaLibraryBrowserProps } from './mediaLibraryBrowser';

export interface MediaBrowserDialogProps extends MediaLibraryBrowserProps {
  open: boolean;
  onCancel: () => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export const MediaBrowserDialog: React.FunctionComponent<MediaBrowserDialogProps> = props => {
  const classes = useStyles({});
  const { open, onCancel, mediaTypes, mediaLibrary, onMediaSelect } = props;
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onCancel}
            color="inherit"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Select media
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <MediaLibraryBrowser
          onMediaSelect={onMediaSelect}
          mediaLibrary={mediaLibrary}
          mediaTypes={mediaTypes}
        />
      </div>
    </Dialog>
  );
};
