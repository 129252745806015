import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { MediaType } from '../commonTypes';
import { MediaLibrarySelectors } from '../mediaLibrary';
import { mediaTypeToViewMode } from './duck/mediaTypeMapper';
import * as Selectors from './duck/selectors';
import { fetchChildren, navigateBack, navigateTo } from './duck/thunks';
import { NavigatorState, ViewMode } from './duck/types';
import { Navigator, NavigatorActions, NavigatorProps } from './navigator';

const mapStateToProps: MapStateToProps<
  NavigatorProps,
  any,
  { navigator: NavigatorState }
> = (state, _) => {
  const navigatorInfo = Selectors.selectNavigatorInfo(state.navigator);
  return {
    ...navigatorInfo,
    mediaLibraryLoading: MediaLibrarySelectors.selectMediaLibraryFetchState(state).isFetching,
    fetchState: Selectors.selectFetchState(state.navigator)
  };
};

const mapDispatchToProps: MapDispatchToProps<NavigatorActions, any> = (
  dispatch,
  _
) => {
  return {
    getChildren: (parentId: number, type: MediaType) =>
      dispatch<any>(fetchChildren(parentId, mediaTypeToViewMode(type))),
    navigateTo: (from: number, to: number, toName: string, type: MediaType, viewMode?: ViewMode) => {
      const vm = viewMode ? viewMode : mediaTypeToViewMode(type);
      dispatch<any>(navigateTo(to, toName, vm, type));
    },
    navigateBack: () => {
      dispatch<any>(navigateBack());
    }
  };
};

export const NavigatorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigator);
