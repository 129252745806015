import { createAction } from 'typesafe-actions';

import { MediaItem, MediaType } from '../../commonTypes';
import { ActionTypes, ViewMode } from './types';

export const childrenFetchRequest = createAction(
  ActionTypes.CHILDREN_FETCH_REQUEST,
  action => (itemId: number, viewMode: ViewMode) =>
    action({ parentId: itemId, parentType: viewMode })
);

export const childrenFetchSuccess = createAction(
  ActionTypes.CHILDREN_FETCH_SUCCESS,
  action => (itemId: number, viewMode: ViewMode, children: any[]) =>
    action({ parentId: itemId, parentType: viewMode, children })
);

export const childrenFetchError = createAction(
  ActionTypes.CHILDREN_FETCH_ERROR,
  action => (itemId: number, viewMode: ViewMode, error: string) =>
    action({ parentId: itemId, parentType: viewMode, error })
);

export const navigateTo = createAction(
  ActionTypes.NAVIGATED_TO_CHILD,
  action => (newItemId: number, newItemName: string, viewMode: ViewMode, mediaType: MediaType, backwards: boolean = false, children?: MediaItem[]) =>
    action({
      newParentId: newItemId,
      newParentName: newItemName,
      parentType: viewMode,
      mediaType,
      children,
      backwards
    })
);

export const navigateBackToParent = createAction(
  ActionTypes.NAVIGATE_BACK,
  action => () => action()
);
