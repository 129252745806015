import classNames from 'classnames';
import React, { useCallback } from 'react';

import { Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import TagIcon from '@material-ui/icons/LocalOffer';
import MediaLibIcon from '@material-ui/icons/PermMedia';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import ChannelIcon from '@material-ui/icons/Theaters';

import { MediaType } from '../../commonTypes';
import { useStyles as useAvatarStyles } from '../../mediaLibrary/mediaAvatar.jss';
import { ViewMode } from '../duck/types';
import { ViewTypeProps } from './types';
import { useStyles } from './workgroupViewType.jss';

interface GridCardProps {
  name: string;
  avatarClass: string;
  label: string;
  count?: number;
  icon: JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const GridCard: React.FunctionComponent<GridCardProps> = props => {
  const classes = useStyles({});
  const avatarClasses = useAvatarStyles();
  return (
    <Card>
      <CardActionArea
        name={props.name}
        className={classes.cardAction}
        onClick={props.onClick}
      >
        <div className={classes.cardLayout}>
          <div className={classes.cardBadge}>
            <Avatar
              className={classNames([
                classes.cardAvatar,
                avatarClasses.avatar,
                props.avatarClass
              ])}
            >
              {props.icon}
            </Avatar>
            {props.count && <Typography variant="h4">{props.count}</Typography>}
          </div>
          <Typography className={classes.cardLabel} variant="h6">
            {props.label}
          </Typography>
        </div>
      </CardActionArea>
    </Card>
  );
};

export const WorkgroupViewType: React.FunctionComponent<ViewTypeProps> = props => {
  const classes = useStyles({});
  const avatarClasses = useAvatarStyles();
  const { items, navigateTo, id } = props;
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const viewMode = Number(e.currentTarget.name);
      navigateTo(
        id,
        id,
        upperToNormalText(e.currentTarget.innerText),
        MediaType.Workgroup,
        viewMode
      );
    },
    [items, navigateTo]
  );

  // const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={6} sm={3}>
            <GridCard
              avatarClass={avatarClasses.channel}
              name={ViewMode.Channels.toString()}
              onClick={onClick}
              icon={<ChannelIcon />}
              label="Channels"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <GridCard
              avatarClass={avatarClasses.playlist}
              name={ViewMode.Playlists.toString()}
              onClick={onClick}
              icon={<PlaylistIcon />}
              label="Playlists"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <GridCard
              avatarClass={avatarClasses.tag}
              name={ViewMode.Tags.toString()}
              onClick={onClick}
              icon={<TagIcon />}
              label="Tags"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <GridCard
              avatarClass={avatarClasses.media}
              name={ViewMode.MediLibrary.toString()}
              onClick={onClick}
              icon={<MediaLibIcon />}
              label="Media Library"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const upperToNormalText = (text: string) => {
  return text[0] + text.substring(1).toLowerCase();
};
