import { Dispatch } from 'redux';

import { MVisionData } from '../../mvisionIntegration';
import * as Actions from './actions';
import { MediaLibraryApi } from './api';

export const fetchMediaLibrary = () => {
    return async (dispatch: Dispatch<any>) => {
      dispatch(Actions.mediaLibraryFetchRequest(MVisionData.workgroupId));
      try {
        const mediaItems = await MediaLibraryApi.fetchMediaLibrary(MVisionData.workgroupId);
        dispatch(
          Actions.mediaLibraryFetchSuccess(MVisionData.workgroupId, mediaItems)
        );
      } catch (err) {
        dispatch(Actions.mediaLibraryFetchError(MVisionData.workgroupId, err));
      }
    };
  };
  