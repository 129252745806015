import React, { useCallback, useState } from 'react';

import {
    Divider, FormLabel, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { MediaItem, MediaLibrary, MediaType } from '../../commonTypes';
import { MediaBrowserDialog } from '../../mediaLibrary';
import { useStyles } from './mediaFields.jss';

export interface MediaReferenceFieldProps {
  value: number[];
  label: string;
  onChange: (newValue: number[]) => void;
  mediaLibrary: MediaLibrary;
  mediaTypes: MediaType[];
}

export const MediaReferenceField: React.FunctionComponent<MediaReferenceFieldProps> = props => {
  const classes = useStyles({});
  const [isBrowserDialogOpen, setBrowserDialogOpen] = useState(false);
  const { value, label, mediaTypes, onChange } = props;
  const onDelete = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    e => {
      if (onChange) {
        const indexToRemove = Number(
          e.currentTarget.getAttribute('data-index')
        );
        if (indexToRemove !== undefined) {
          let newArray = [...value];
          newArray.splice(indexToRemove, 1);
          onChange(newArray);
        }
      }
    },
    [value, onChange]
  );
  const onNewItem = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    _ => setBrowserDialogOpen(true),
    [setBrowserDialogOpen]
  );
  const onMediaSelected = useCallback(
    (media: MediaItem) => {
      setBrowserDialogOpen(false);
      let newArray = [...value, media.id];
      onChange(newArray);
    },
    [value, onChange]
  );
  const onCancel = useCallback(() => setBrowserDialogOpen(false), [
    setBrowserDialogOpen
  ]);

  return (
    <>
      <List>
        <ListItem disableGutters={true}>
          <ListItemText className={classes.listItem}>
            <FormLabel>{label}</FormLabel>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton onClick={onNewItem} edge="end">
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {props.value.map((v, index) => (
          <ListItem key={v} disableGutters={true}>
            <ListItemText
              className={classes.listItem}
              primary={
                props.mediaLibrary.items[v]
                  ? props.mediaLibrary.items[v].name
                  : v
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                data-index={index}
                aria-label="delete"
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <MediaBrowserDialog
        mediaTypes={mediaTypes}
        mediaLibrary={props.mediaLibrary}
        open={isBrowserDialogOpen}
        onMediaSelect={onMediaSelected}
        onCancel={onCancel}
      />
    </>
  );
};
