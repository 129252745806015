import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: '#fff'
    },
    channel: {
      backgroundColor: '#6AC75E'
    },
    playlist: {
      backgroundColor: '#4EAFA3'
    },
    folder: {
      backgroundColor: theme.palette.grey[500]
    },
    tag: {
      backgroundColor: '#C23281'
      // #e9a64e
    },
    media: {
      backgroundColor: '#75578C'
      // #e9a64e
    },
    design: {
      backgroundColor: '#e9a64e'
    }
  })
);
