import { addMilliseconds, format } from 'date-fns';
import { FastField, FieldProps, FormikProps } from 'formik';
import React from 'react';

import { Checkbox, FormControlLabel, Grid, Slider, TextField, Typography } from '@material-ui/core';

import { Media, VideoProperties } from '../../duck/types';
import { useStyles } from '../mediaInfo.jss';

const millisecondsToDuration = (milliseconds: number | undefined): string => {
  if (!milliseconds) {
    return '';
  }
  var helperDate = addMilliseconds(new Date(0, 0, 0, 0, 0, 0, 0), milliseconds);
  return format(helperDate, 'HH:mm:ss.SSS');
};

const VolumeSlider: React.FunctionComponent<{volume: number, onChange: (volume: number) => void}> = React.memo(props => {
  const classes = useStyles({});
  const {volume, onChange} = props;
  const [value, setValue] = React.useState(volume);
  const onChangeCommit = React.useCallback((_: React.ChangeEvent<{}>, newValue: number | number[]) => onChange(newValue as number),
    [onChange]
  );
  const innerOnChange = React.useCallback(
    (_: React.ChangeEvent<{}>, value: number | number[]) => {
      setValue(value as number);
    },
    [setValue]
  );
  const volumeMarks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 100,
      label: '100'
    }
  ];
  return (<div className={classes.field}>
    <Typography id="continuous-slider" gutterBottom>
      Volume
    </Typography>
        <Slider
          marks={volumeMarks}
          valueLabelDisplay="on"
          value={value}
          onChange={innerOnChange}
          onChangeCommitted={onChangeCommit}
          aria-labelledby="volume-slider"
        />
  </div>);
})
export const Video: React.FunctionComponent<VideoProperties> = props => {
  const classes = useStyles({});
  
  const onVolumeChange = React.useCallback(
    (
      form: FormikProps<Media>,
      fieldName: string,
      newValue: number | number[]
    ) => form.setFieldValue(fieldName, newValue),
    []
  );
  
  return (
    <>
      <FastField
        name="properties.keepAspectRatio"
        render={({ field, form }: FieldProps<Media>) => (
          <FormControlLabel
            className={classes.field}
            control={
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
              />
            }
            label="Keep aspect ratio"
          />
        )}
      />
      <TextField
        className={classes.field}
        label="Size"
        fullWidth
        value={`${props.width}x${props.height}`}
        InputProps={{ readOnly: true }}
      />
      <TextField
        className={classes.field}
        label="Original duration"
        fullWidth
        value={millisecondsToDuration(props.trueDuration)}
        InputProps={{ readOnly: true }}
        helperText="HH:mm:ss.SSS"
      />
      <FastField
        name="properties.soundVolume"
        render={({ field, form }: FieldProps<Media>) => (
          <VolumeSlider volume={field.value} onChange={(volume => form.setFieldValue(field.name, volume))}/>
        )}
      />
    </>
  );
};
