import { connect, MapStateToProps } from 'react-redux';

import { selectMediaLibrary } from '../../mediaLibrary/duck/selectors';
import { MediaReferenceField, MediaReferenceFieldProps } from './mediaReferenceField';

export interface MediaReferenceFieldContainerProps
  extends Omit<MediaReferenceFieldProps, 'mediaLibrary'> {}

const mapStateToProps: MapStateToProps<
  MediaReferenceFieldProps,
  MediaReferenceFieldContainerProps,
  any
> = (state, ownProps) => {
  const mediaLibrary = selectMediaLibrary(state);
  return {
    ...ownProps,
    mediaLibrary,
  };
};

export const MediaReferenceFieldContainer = connect(mapStateToProps)(MediaReferenceField);
