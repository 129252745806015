import produce from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { MediaType } from '../../commonTypes';
import { MVisionData } from '../../mvisionIntegration';
import * as Actions from './actions';
import { ActionTypes, NavigatorState, ViewMode } from './types';

type NavigatorActions = ActionType<typeof Actions>;

const initialState: NavigatorState = {
  children: [],
  childrenFetchState: {
    fetchCompleted: false,
    fetchError: '',
    id: 0,
    isFetching: false
  },
  path: [
    {
      id: MVisionData.workgroupId,
      name: MVisionData.workgroupName,
      viewMode: ViewMode.Workgroup,
      mediaType: MediaType.Workgroup,
    }
  ]
};

export const navigatorReducer: Reducer<NavigatorState, NavigatorActions> = (
  state = initialState,
  action
) =>
  produce(state, draftState => {
    switch (action.type) {
      case ActionTypes.CHILDREN_FETCH_REQUEST: {
        draftState.childrenFetchState.fetchCompleted = false;
        draftState.childrenFetchState.fetchError = '';
        draftState.childrenFetchState.id = action.payload.parentId;
        draftState.childrenFetchState.isFetching = true;
        break;
      }
      case ActionTypes.CHILDREN_FETCH_SUCCESS: {
        draftState.childrenFetchState.fetchCompleted = true;
        draftState.childrenFetchState.fetchError = '';
        draftState.childrenFetchState.isFetching = false;
        draftState.childrenFetchState.id = 0;
        draftState.children = action.payload.children;
        break;
      }
      case ActionTypes.CHILDREN_FETCH_ERROR: {
        draftState.childrenFetchState.fetchCompleted = true;
        draftState.childrenFetchState.fetchError = action.payload.error;
        draftState.childrenFetchState.isFetching = false;
        draftState.childrenFetchState.id = 0;
        break;
      }
      case ActionTypes.NAVIGATED_TO_CHILD: {
        if (action.payload.children) {
          draftState.children = action.payload.children;
        }
        if (action.payload.backwards) {
          draftState.path.splice(-1, 1);
        } else {
          draftState.path.push({
            id: action.payload.newParentId,
            name: action.payload.newParentName,
            viewMode: action.payload.parentType,
            mediaType: action.payload.mediaType
          });
        }
        break;
      }
      case ActionTypes.NAVIGATE_BACK: {
        break;
      }
    }
  });
