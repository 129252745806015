import React, { useCallback, useState } from 'react';

import { AppBar, Fab, IconButton, Toolbar, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Home from '@material-ui/icons/Home';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { FetchState, MediaItem, MediaType } from '../commonTypes';
import { PrrogressIndicator } from '../components/progressIndicator';
import { MediaEditorContainer } from '../editor';
import { MVisionData } from '../mvisionIntegration';
import { ViewMode } from './duck/types';
import { useStyles } from './navigator.jss';
import * as ViewTypes from './viewTypes';

export interface NavigatorProps {
  mediaLibraryLoading: boolean;
  parentId: number;
  parentName: string;
  parentMediaType: MediaType;
  viewMode: ViewMode;
  items: MediaItem[];
  fetchState: FetchState;
}

export interface NavigatorActions {
  navigateTo: (
    from: number,
    to: number,
    toName: string,
    type: MediaType,
    viewMode?: ViewMode
  ) => void;
  getChildren: (parentId: number, type: MediaType) => void;
  navigateBack: () => void;
}

type Props = NavigatorProps & NavigatorActions;

export const Navigator: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { navigateBack } = props;
  const [editMode, setEditMode] = useState(false);
  const onBack = useCallback(() => {
    if (!editMode) {
      navigateBack();
      return;
    }
    setEditMode(false);
  }, [navigateBack, editMode]);


  const onEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  let viewComponent: JSX.Element;
  switch (props.viewMode) {
    case ViewMode.Workgroup: {
      viewComponent = (
        <ViewTypes.WorkgroupViewType
          items={props.items}
          navigateTo={props.navigateTo}
          id={props.parentId}
          name={props.parentName}
        />
      );
      break;
    }
    case ViewMode.Media: {
      viewComponent = (
        <MediaEditorContainer
          mediaId={props.parentId}
          mediaType={props.parentMediaType}
          onCancel={() => setEditMode(false)}
        />
      );
      break;
    }
    default:
      viewComponent = (
        <ViewTypes.DefaultViewType
          items={props.items}
          navigateTo={props.navigateTo}
          id={props.parentId}
          name={props.parentName}
        />
      );
  }

  return (
    <div className={classes.container}>
      <AppBar position="sticky">
        <Toolbar className={classes.toolbar}>
          <IconButton color="secondary" onClick={onBack}>
            <KeyboardArrowLeft />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {props.parentName}
          </Typography>
          <IconButton color="inherit" onClick={MVisionData.backFn}>
            <Home />
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <div className={classes.content}>
        {props.fetchState.isFetching || props.mediaLibraryLoading ? (
          <PrrogressIndicator />
        ) : editMode ? (
          <MediaEditorContainer
            mediaType={props.parentMediaType}
            mediaId={props.parentId}
            onCancel={() => setEditMode(false)}
          />
        ) : (
          viewComponent
        )}
        {!editMode &&
          props.parentMediaType !== MediaType.Workgroup &&
          props.parentMediaType !== MediaType.Channel &&
          props.viewMode !== ViewMode.Media && (
            <Fab
              color="secondary"
              aria-label="edit"
              className={classes.fab}
              onClick={onEdit}
            >
              <EditIcon />
            </Fab>
          )}
      </div>
    </div>
  );
};
