import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      backgroundColor: theme.palette.background.default
    },
  })
);
