import produce from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { MediaLibrary } from '../../commonTypes';
import * as Actions from './actions';
import { ActionTypes, MediaLibraryState } from './types';

type MediaLibraryActions = ActionType<typeof Actions>;

const initialState: MediaLibraryState = {
  mediaLibrary: {
    items: {},
    types: {}
  },
  mediaLibraryFetchState: {
    fetchCompleted: false,
    fetchError: '',
    id: 0,
    isFetching: false
  }
};

export const mediaLibraryReducer: Reducer<
  MediaLibraryState,
  MediaLibraryActions
> = (state = initialState, action) =>
  produce(state, draftState => {
    switch (action.type) {
      case ActionTypes.MEDIA_LIBRARY_REQUEST: {
        draftState.mediaLibraryFetchState.fetchCompleted = false;
        draftState.mediaLibraryFetchState.fetchError = '';
        draftState.mediaLibraryFetchState.id = action.payload.workgroupId;
        draftState.mediaLibraryFetchState.isFetching = true;
        break;
      }
      case ActionTypes.MEDIA_LIBRARY_SUCCESS: {
        draftState.mediaLibraryFetchState.fetchCompleted = true;
        draftState.mediaLibraryFetchState.fetchError = '';
        draftState.mediaLibraryFetchState.isFetching = false;
        draftState.mediaLibraryFetchState.id = 0;
        draftState.mediaLibrary = action.payload.items.reduce<MediaLibrary>(
          (ml, mediaItem) => {
            ml.items[mediaItem.id] = mediaItem;
            if (!ml.types[mediaItem.mediaType]) {
              ml.types[mediaItem.mediaType] = [];
            }
            ml.types[mediaItem.mediaType].push(mediaItem.id);
            return ml;
          },
          { items: {}, types: {} }
        );
        break;
      }
      case ActionTypes.MEDIA_LIBRARY_ERROR: {
        draftState.mediaLibraryFetchState.fetchCompleted = true;
        draftState.mediaLibraryFetchState.fetchError = action.payload.err;
        draftState.mediaLibraryFetchState.isFetching = false;
        draftState.mediaLibraryFetchState.id = 0;
        break;
      }
    }
  });
