import { MediaItem, MediaType } from '../../commonTypes';

export const MediaLibraryApi = {
  fetchMediaLibrary: (workgroupId: number): Promise<MediaItem[]> => {
    return fetch(process.env.REACT_APP_ROOT_DOMAIN + 'v6/media', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
          'MVision-Current-WorkgroupId': workgroupId.toString(),
      },
    }).then(response => response.json());
  },
  generateThumbnailUrl: (
    mediaType: MediaType,
    contentFileId: number,
    size: 'big' | 'small' = 'big'
  ) => {
    let url = process.env.REACT_APP_ROOT_DOMAIN;
    switch (mediaType) {
      case MediaType.Image:
      case MediaType.Video:
      case MediaType.Flash:
      case MediaType.HtmlTemplate:
      case MediaType.HtmlInstance:
      case MediaType.FlashEditableTemplate:
      case MediaType.FlashEditable:
      case MediaType.WebPage:
        url =
          url +
          `v5/medias/
          ${contentFileId}/thumbnails/${contentFileId}_${size}_1.jpg`;
        break;
      case MediaType.Audio:
        url =
        url +'v6/Content/images/font-awesome_file_audio.png';
        break;
      case MediaType.TvPassThru:
        url =
          url + 'v5/medias/tvpassthru/thumbnails/tvpassthru_big_1.jpg';
          break;
      case MediaType.TunerTv:
        url = url + 'v5/medias/tunertv/thumbnails/tunertv_big_1.jpg';break;
      case MediaType.AdvancedLocalInputPlaylistChild:
        url = url + 'v5/medias/playlistlocalinputchild/thumbnails/playlistlocalinputchild_big_1.jpg';break;
      case MediaType.AdvancedLocalInputPlaylistParent:
        url = url + 'v5/medias/playlistlocalinputparent/thumbnails/playlistlocalinputparent_big_1.jpg';break;
      case MediaType.AdvancedLocalInputTagChild:
        url = url + 'v5/medias/taglocalinputparent/thumbnails/taglocalinputparent_big_1.jpg';break;
      case MediaType.AdvancedLocalInputTagParent:
        url = url + 'v5/medias/taglocalinputchild/thumbnails/taglocalinputchild_big_1.jpg';break;
      case MediaType.EventPlaylist:
      case MediaType.Playlist:
        url = url + 'v5/medias/playlist/thumbnails/playlist_big_1.jpg';break;
      case MediaType.Tag:
        url = url + 'v5/medias/tag/thumbnails/tag_big_1.jpg';break;

      case MediaType.Channel:
        url = url + 'v5/medias/channel/thumbnails/channel_big_1.jpg';break;

      case MediaType.DesignInstance || MediaType.DesignTemplate:
        url = url + 'v5/medias/designinstance/thumbnails/designinstance_big_1.jpg';break;

      default:
        url = url + 'v5/medias/channel/thumbnails/channel_big_1.jpg';break;
    }
    return url;
  }
};
