import React, { useCallback } from 'react';

import { MediaItem } from '../../commonTypes';
import { MediaList } from '../../mediaLibrary';
import { ViewTypeProps } from './types';

export const DefaultViewType: React.FunctionComponent<ViewTypeProps> = props => {
  const {id, navigateTo} = props;
  const onMediaSelect = useCallback(
    (mediaItem: MediaItem) =>
      navigateTo(
        id,
        mediaItem.id,
        mediaItem.name,
        mediaItem.mediaType
      ),
    [id, navigateTo]
  );
  return (
    <MediaList
      items={props.items}
      onMediaSelect={onMediaSelect}
    />
  );
};
