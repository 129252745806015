import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1)
    },
    searchField: {
        height: '48px',
        margin: theme.spacing(2),
    },
    listContainer: {
      width: '100%',
      flexGrow: 1,
    },
    thumbnailCellContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1)
    },
    thumbnailCellImage: {
      flex: 1
    }
  })
);
