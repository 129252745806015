import { FetchState, MediaItem, MediaType } from '../../commonTypes';

export interface NavigatorState {
    childrenFetchState: FetchState;
    children: MediaItem[],
    path: PathItem[]
}

export interface PathItem{
    id: number;
    name: string;
    viewMode: ViewMode;
    mediaType: MediaType;
}

export enum ViewMode {
    Workgroup,
    Folder,
    Playlist,
    Tag,
    Channels,
    Channel,
    Media,
    MediLibrary,
    Tags,
    Playlists
}



export enum ActionTypes {
    CHILDREN_FETCH_REQUEST = 'CHILDREN_FETCH_REQUEST',
    CHILDREN_FETCH_SUCCESS = 'CHILDREN_FETCH_SUCCESS',
    CHILDREN_FETCH_ERROR = 'CHILDREN_FETCH_ERROR',
    NAVIGATED_TO_CHILD = 'NAVIGATED_TO_CHILD',
    NAVIGATE_BACK = 'NAVIGATE_BACK'
}