import { FastField, FieldProps } from 'formik';
import React from 'react';

import { Paper, TextField } from '@material-ui/core';

import { Media } from '../duck/types';
import { useStyles } from './mediaInfo.jss';
import * as MediaProperties from './mediaProperties';

export interface MediaInfoProps {
  media: Media;
}

const mediaProps = (media: Media): JSX.Element | null => {
  if (MediaProperties.Mappers.isVideo(media.properties, media.type)) {
    return <MediaProperties.Video {...media.properties} />;
  }
  if (MediaProperties.Mappers.isImage(media.properties, media.type)) {
    return <MediaProperties.Image {...media.properties} />;
  }
  return null;
};

export const MediaInfo: React.FunctionComponent<MediaInfoProps> = props => {
  const classes = useStyles({});
  const { media } = props;
  const mediaPropsComponent = mediaProps(media);

  return (
    <div className={classes.container}>
      <Paper className={classes.box}>
        <img src={media.thumbnailBig} alt={media.name} className={classes.thumbnail} />
      </Paper>
      <Paper className={classes.box}>
        <FastField
          name="name"
          render={({ field, form }: FieldProps<Media>) => (
            <TextField
              {...field}
              fullWidth
              className={classes.field}
              label="Name"
            />
          )}
        />
        <TextField
          className={classes.field}
          label="Uploaded by"
          fullWidth
          value={media.userName}
          InputProps={{ readOnly: true }}
        />
        <TextField
          className={classes.field}
          label="Creation date"
          fullWidth
          value={media.creationDate}
          InputProps={{ readOnly: true }}
        />
        <FastField
          name="description"
          render={({ field, form }: FieldProps<Media>) => (
            <TextField
              {...field}
              fullWidth
              multiline
              rowsMax={3}
              helperText='The description has to be less than 500 characters long.'
              className={classes.field}
              label="Description"
            />
          )}
        />
      </Paper>
      {mediaPropsComponent && <Paper className={classes.box}>{mediaPropsComponent}</Paper>}
    </div>
  );
};
