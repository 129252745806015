import { MVisionData } from '../../mvisionIntegration';
import { parseDate, parseTimeSpan } from './dateTime';
import { ChangedMFrameParams, MFrame } from './mFrameTypes';
import { DiffusionDays, Media, MediaInfo } from './types';

const daysToDiffusion = (days: DiffusionDays): number => {
  let diffusionDays = 127;

  if (!days.monday) {
    diffusionDays = diffusionDays & ~64;
  }

  if (!days.tuesday) {
    diffusionDays = diffusionDays & ~32;
  }

  if (!days.wednesday) {
    diffusionDays = diffusionDays & ~16;
  }

  if (!days.thursday) {
    diffusionDays = diffusionDays & ~8;
  }

  if (!days.friday) {
    diffusionDays = diffusionDays & ~4;
  }

  if (!days.saturday) {
    diffusionDays = diffusionDays & ~2;
  }

  if (!days.sunday) {
    diffusionDays = diffusionDays & ~1;
  }
  return diffusionDays;
};

export const MediaApi = {
  getMediaInfo: (mediaId: number): Promise<MediaInfo[]> => {
    return fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/media/batch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString(),
      },
      body: JSON.stringify([mediaId])
    }).then(response => response.json());
  },
  mediaInfoToMedia: (mediaInfo: MediaInfo): Media => {
    const media: Media = {
      name: mediaInfo.name,
      type: mediaInfo.mediaType,
      thumbnailBig: mediaInfo.thumbnailBig,
      userName: mediaInfo.userName,
      id: mediaInfo.id,
      contentId: mediaInfo.contentId,
      creationDate: parseDate(mediaInfo.creationDate),
      description: mediaInfo.description,
      startDate: parseDate(mediaInfo.startDate),
      startTime: parseTimeSpan(mediaInfo.startTime),
      endDate: parseDate(mediaInfo.endDate),
      endTime: parseTimeSpan(mediaInfo.endTime),
      properties: mediaInfo.properties,
      diffusionDays: {
        monday: (mediaInfo.diffusionDays & 64) !== 0,
        tuesday: (mediaInfo.diffusionDays & 32) !== 0,
        wednesday: (mediaInfo.diffusionDays & 16) !== 0,
        thursday: (mediaInfo.diffusionDays & 8) !== 0,
        friday: (mediaInfo.diffusionDays & 4) !== 0,
        saturday: (mediaInfo.diffusionDays & 2) !== 0,
        sunday: (mediaInfo.diffusionDays & 1) !== 0
      }
    };
    return media;
  },
  mediaToMediaInfo: (media: Media, mediaInfo: MediaInfo): MediaInfo => {
    const newMediaInfo = {
      ...mediaInfo,
      name: media.name,
      diffusionDays: daysToDiffusion(media.diffusionDays),
      startDate: media.startDate.toUTCString(),
      endDate: media.endDate.toUTCString(),
      properties: media.properties,
    };
    return newMediaInfo;
  },
  getHtmlInstanceFields: (mediaId: number): Promise<MFrame> => {
    return fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/htmlInstance/${mediaId}`, {
      headers: {
        'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString(),
      },
    }).then(response =>
      response.json()
    );
  },
  updateMedia: (
    media: Media,
    mediaInfo: MediaInfo,
    changedFields: ChangedMFrameParams[]
  ): Promise<any> => {
    const newMediaInfo = MediaApi.mediaToMediaInfo(media, mediaInfo);
    const promises = changedFields.map(field =>
      fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/htmlInstance/update/${mediaInfo.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString(),
        },
        body: JSON.stringify({
          component: field.componentName,
          param: field.paramName,
          value: field.newValue,
          otherValues: {
            filenames: null
          }
        })
      })
    );
      
    promises.push(fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/media/batchUpdate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString(),
      },
      body: JSON.stringify([newMediaInfo])
    }));

    return Promise.all(promises);
  }
};
