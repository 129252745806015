import React from 'react';

import { MediaItem, MediaLibrary, MediaType } from '../commonTypes';
import { MediaList, MediaListProps } from './mediaList';

export interface MediaLibraryBrowserProps extends Omit<MediaListProps, 'items'> {
  mediaTypes: MediaType[];
  mediaLibrary: MediaLibrary;
}

export const MediaLibraryBrowser: React.FunctionComponent<MediaLibraryBrowserProps> = React.memo(
  props => {
    const { mediaLibrary, mediaTypes } = props;
    const items = React.useMemo(
      () =>
        mediaTypes.reduce<MediaItem[]>(
          (a, type) =>
            a.concat(
              mediaLibrary.types[type].map(id => mediaLibrary.items[id])
            ),
          []
        ),
      [mediaLibrary, mediaTypes]
    );
    return (
      <MediaList
        items={items}
        onMediaSelect={props.onMediaSelect}
      />
    );
  }
);
