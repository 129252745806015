import { Field, FieldProps } from 'formik';
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Paper } from '@material-ui/core';
import {
    KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';

import { Media } from '../duck/types';
import { DaysForm } from './daysForm';
import { useStyles } from './mediaSchedule.jss';

export interface MediaScheduleProps {}

// const isEntireDay = (startTime: Date, endTime: Date) =>
//   DateFns.getHours(startTime) === 0 &&
//   DateFns.getMinutes(startTime) === 0 &&
//   DateFns.getSeconds(startTime) === 0 &&
//   DateFns.getHours(endTime) === 23 &&
//   DateFns.getMinutes(endTime) === 59 &&
//   DateFns.getSeconds(endTime) === 59;

export const MediaSchedule = (props: MediaScheduleProps) => {
  const classes = useStyles({});
  return (
    <Paper className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.form}>
          <Field
            name="startDate"
            render={({ field, form }: FieldProps<Media>) => (
              <KeyboardDatePicker
                margin="normal"
                id="start-date"
                label="Start date"
                format="dd.MM.yyyy"
                value={field.value}
                onChange={d => {
                  form.setFieldValue('startDate', d);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            )}
          />

          <Field
            name="endDate"
            render={({ field, form }: FieldProps<Media>) => (
              <KeyboardDatePicker
                margin="normal"
                id="end-date"
                label="End date"
                format="dd.MM.yyyy"
                value={field.value}
                onChange={d => {
                  form.setFieldValue('endDate', d);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            )}
          />
          {/* <EntireDaySwitch
          entireDay={entireDay}
          onChange={handleEntireDayChange}
        /> */}
          <Field
            name="startTime"
            render={({ field, form }: FieldProps<Media>) => (
              <KeyboardTimePicker
                margin="normal"
                id="start-time"
                label="Start time"
                value={field.value}
                onChange={(date, value) =>
                  form.setFieldValue(field.name, date as Date)
                }
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            )}
          />
          <Field
            name="endTime"
            render={({ field, form }: FieldProps<Media>) => (
              <KeyboardTimePicker
                margin="normal"
                id="end-time"
                label="End time"
                value={field.value}
                onChange={(date, value) =>
                  form.setFieldValue(field.name, date as Date)
                }
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            )}
          />
          <DaysForm />
        </div>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};
