import { FetchState, MediaLibrary } from '../../commonTypes';

export interface MediaLibraryState {
    mediaLibraryFetchState: FetchState;
    mediaLibrary: MediaLibrary,
}

export enum ActionTypes {
    MEDIA_LIBRARY_REQUEST = 'MEDIA_LIBRARY_REQUEST',
    MEDIA_LIBRARY_SUCCESS = 'MEDIA_LIBRARY_SUCCESS',
    MEDIA_LIBRARY_ERROR = 'MEDIA_LIBRARY_ERROR',
}