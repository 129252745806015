import { Dispatch } from 'redux';

import { MediaType } from '../../commonTypes';
import * as Actions from './actions';
import { MediaApi } from './api';
import { ChangedMFrameParams } from './mFrameTypes';
import { EditorState, Media } from './types';

export const fetchMediaInfo = (mediaId: number, mediaType: MediaType) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(Actions.mediaInfoFetchRequest(mediaId));
    try {
      const mediaInfoData = await MediaApi.getMediaInfo(mediaId);
      if (mediaInfoData.length === 0) {
        throw new Error(`No info for media ${mediaId}`);
      }
      const mediaInfo = mediaInfoData[0];
      const media = MediaApi.mediaInfoToMedia(mediaInfo);
      if(mediaInfo.mediaType === MediaType.HtmlInstance) {
        const mFrame = await MediaApi.getHtmlInstanceFields(mediaId);
        media.mFrame = mFrame;
      }
      dispatch(Actions.mediaInfoFetchSuccess(mediaInfo, media));
    } catch (err) {
      dispatch(Actions.mediaInfoFetchError(mediaId, err));
    }
  };
};

export const updateMedia = (media: Media, changedFields: ChangedMFrameParams[]) => {
  return async (
    dispatch: Dispatch<any>,
    getState: () => { editor: EditorState }
  ) => {
    dispatch(Actions.mediaUpdateRequest(media));
    const mediaInfo = getState().editor.mediaInfo;
    try {
      if (!mediaInfo) {
        return;
      }
      await MediaApi.updateMedia(media, mediaInfo, changedFields);
      dispatch(Actions.mediaUpdateSuccess(mediaInfo.id));
    } catch (err) {
      if (mediaInfo) {
        dispatch(Actions.mediaUpdateError(mediaInfo.id, err));
      }
    }
  };
};
