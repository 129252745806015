import React from 'react';

import {
    Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Switch
} from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { MediaType, MediaTypeLabels } from '../commonTypes';
import { useStyles } from './mediaListFilter.jss';

export interface MediaListFilterProp {
  open: boolean;
  mediaTypes: MediaType[];
  selectedMediaTypes: MediaType[];
  showThumbnails: boolean;
  onClosed: (mediaTypes: MediaType[], showThumbnails: boolean) => void;
}

export const MediaListFilter: React.FunctionComponent<MediaListFilterProp> = props => {
  const classes = useStyles({});
  const { mediaTypes, selectedMediaTypes, onClosed } = props;
  const [selectedTypes, setSelectedTypes] = React.useState(selectedMediaTypes);
  const [showThumbnails, setShowThumbnails] = React.useState(
    props.showThumbnails
  );
  const onDrawerClose = React.useCallback(
    (e: React.SyntheticEvent<{}, Event>) => {
      onClosed(selectedTypes, showThumbnails);
    },
    [selectedTypes, onClosed, showThumbnails]
  );
  const handleCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const type = Number(event.target.value) as MediaType;
      if (event.target.checked) {
        setSelectedTypes([...selectedTypes, type]);
      } else {
        const newSelectedTypes = [...selectedTypes];
        newSelectedTypes.splice(newSelectedTypes.indexOf(type), 1);
        setSelectedTypes(newSelectedTypes);
      }
    },
    [selectedTypes]
  );
  const handleThumbnailsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLElement>, checked: boolean) => {
      setShowThumbnails(checked);
    },
    []
  );
  return (
    <SwipeableDrawer
      anchor="right"
      open={props.open}
      onClose={onDrawerClose}
      onOpen={_ => {}}
    >
      <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Media types</FormLabel>
          <FormGroup>
            {mediaTypes.map(mt => (
              <FormControlLabel
                key={mt}
                control={
                  <Checkbox
                    checked={selectedTypes.indexOf(mt) > -1}
                    onChange={handleCheckboxChange}
                    value={mt}
                  />
                }
                label={MediaTypeLabels[mt]}
              />
            ))}
          </FormGroup>
          {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">View mode</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showThumbnails}
                  onChange={handleThumbnailsChange}
                  value="thumbnails"
                />
              }
              label="Show thumbnails"
            />
          </FormGroup>
        </FormControl>
        <Button variant='contained' color='secondary' onClick={onDrawerClose}>Close</Button>
      </div>
    </SwipeableDrawer>
  );
};
