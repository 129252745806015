import React from 'react';
import Measure from 'react-measure';
import {
    FixedSizeGrid, FixedSizeList, GridChildComponentProps, ListChildComponentProps
} from 'react-window';
import { useDebouncedCallback } from 'use-debounce';

import {
    IconButton, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { MediaItem, MediaType } from '../commonTypes';
import { MediaLibraryApi } from './duck/api';
import { MediaAvatar } from './mediaAvatar';
import { useStyles } from './mediaList.jss';
import { MediaListFilter } from './mediaListFilter';

export interface MediaListProps {
  items: MediaItem[];
  onMediaSelect: (selectedMedia: MediaItem) => void;
}

function renderRow(props: ListChildComponentProps) {
  const { index, style, data } = props;

  return (
    <ListItem
      button
      style={style}
      key={data.medias[index].id}
      onClick={data.handleSelect}
      data-index={index}
      divider
    >
      <ListItemAvatar>
        <MediaAvatar mediaType={data.medias[index].mediaType} />
      </ListItemAvatar>
      <ListItemText
        primary={data.medias[index].name}
        primaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  );
}

function renderCell(props: GridChildComponentProps) {
  const { columnIndex, rowIndex, style, data } = props;
  const media = data.medias[rowIndex * 2 + columnIndex];
  return (
    <div style={style} className={data.classes.thumbnailCellContainer}>
      <img
        className={data.classes.thumbnailCellImage}
        src={MediaLibraryApi.generateThumbnailUrl(
          media.mediaType,
          media.contentId
        )}
        alt={'img'}
      />
      <Typography variant="caption">{media.name}</Typography>
    </div>
  );
}

interface MediaListState {
  mediaTypes: MediaType[];
  selectedMediaTypes: MediaType[];
  searchTerm: string;
  showThumbnails: boolean;
  drawerOpen: boolean;
}

export const MediaList: React.FunctionComponent<MediaListProps> = props => {
  const classes = useStyles({});
  const { onMediaSelect, items } = props;
  const [state, setState] = React.useState<MediaListState>(() => {
    const mediaTypes = props.items.reduce<MediaType[]>((a, i) => {
      if (a.indexOf(i.mediaType) === -1) {
        a.push(i.mediaType);
      }
      return a;
    }, []);
    return {
      mediaTypes,
      selectedMediaTypes: mediaTypes,
      searchTerm: '',
      showThumbnails: false,
      drawerOpen: false
    };
  });
  const [dimension, setDimension] = React.useState({
    width: 0,
    height: 0
  });
  const listData = React.useMemo(() => {
    const searchTerm = state.searchTerm ? state.searchTerm.toLowerCase() : null;
    const medias = items.filter(i => {
      if (
        state.mediaTypes.length !== state.selectedMediaTypes.length &&
        state.selectedMediaTypes.indexOf(i.mediaType) === -1
      ) {
        return false;
      }
      if (searchTerm && i.name.toLowerCase().indexOf(searchTerm) === -1) {
        return false;
      }
      return true;
    });

    const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const index = Number(e.currentTarget.getAttribute('data-index'));
      onMediaSelect(medias[index]);
    };
    return { medias, handleSelect, classes };
  }, [
    classes,
    onMediaSelect,
    items,
    state.selectedMediaTypes,
    state.mediaTypes,
    state.searchTerm
  ]);

  const onFilterOpen = React.useCallback(
    () => setState({ ...state, drawerOpen: true }),
    [state, setState]
  );
  const onFilterDrawerClose = React.useCallback(
    (selectedMediaTypes: MediaType[], showThumbnails: boolean) =>
      setState({
        ...state,
        selectedMediaTypes,
        showThumbnails,
        drawerOpen: false
      }),
    [state]
  );

  const [onSearchTextFieldChange] = useDebouncedCallback(
    // function
    value => {
      setState({ ...state, searchTerm: value });
    },
    // delay in ms
    300
  );

  return (
    <Paper className={classes.listRoot}>
      <MediaListFilter
        mediaTypes={state.mediaTypes}
        showThumbnails={state.showThumbnails}
        selectedMediaTypes={state.selectedMediaTypes}
        open={state.drawerOpen}
        onClosed={onFilterDrawerClose}
      />
      <TextField
        className={classes.searchField}
        placeholder="Search"
        onChange={e => onSearchTextFieldChange(e.target.value)}
        autoFocus
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="filter"
              color="primary"
              onClick={onFilterOpen}
            >
              <FilterListIcon />
            </IconButton>
          )
        }}
      />
      <Measure
        bounds
        onResize={contentRect => {
          if (contentRect.bounds) {
            setDimension({
              width: contentRect.bounds.width,
              height: contentRect.bounds.height
            });
          }
        }}
      >
        {({ measureRef }) => (
          <div className={classes.listContainer} ref={measureRef}>
            {state.showThumbnails ? (
              <FixedSizeGrid
                height={dimension.height}
                width={dimension.width}
                itemData={listData}
                rowCount={Math.ceil(listData.medias.length / 2)}
                columnCount={2}
                rowHeight={(window as any).innerWidth / 2}
                columnWidth={(window as any).innerWidth / 2}
              >
                {renderCell}
              </FixedSizeGrid>
            ) : (
              <FixedSizeList
                height={dimension.height}
                width={dimension.width}
                itemSize={56}
                itemCount={listData.medias.length}
                itemData={listData}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </div>
        )}
      </Measure>
    </Paper>
  );
};
