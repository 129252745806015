import { MediaType } from '../../commonTypes';
import { NavigatorState, ViewMode } from './types';

export const selectNavigatorInfo = (state: NavigatorState) => {
  if (state.path.length === 0)
    return {
      parentId: 0,
      parentName: '',
      viewMode: ViewMode.Workgroup,
      items: [],
      parentMediaType: MediaType.Workgroup
    };
  const lastItem = state.path[state.path.length - 1];
  return {
    parentId: lastItem.id,
    parentName: lastItem.name,
    parentMediaType: lastItem.mediaType,
    viewMode: lastItem.viewMode,
    items: state.children
  };
};

export const selectFetchState = (state: NavigatorState) => {
  return state.childrenFetchState;
};

export const selectPath = (state: NavigatorState) => {
  return state.path;
};