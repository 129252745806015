import { Dispatch } from 'redux';

import { MediaType } from '../../commonTypes';
import { MediaLibraryState } from '../../mediaLibrary/duck/types';
import * as Actions from './actions';
import { api } from './api';
import { NavigatorState, ViewMode } from './types';

export const fetchChildren = (parentId: number, viewMode: ViewMode) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(Actions.childrenFetchRequest(parentId, viewMode));
    try {
      const children = await api.fetchChildren(parentId, viewMode);
      dispatch(Actions.childrenFetchSuccess(parentId, viewMode, children));
    } catch (err) {
      dispatch(Actions.childrenFetchError(parentId, viewMode, err));
    }
  };
};

export const navigateTo = (
  parentId: number,
  parentName: string,
  viewMode: ViewMode,
  mediaType: MediaType,
  backwards: boolean = false
) => {
  return async (
    dispatch: Dispatch<any>,
    getState: () => {mediaLibrary: MediaLibraryState }
  ) => {
    const state = getState();
    switch (viewMode) {
      case ViewMode.Workgroup: {
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards
          )
        );
        break;
      }
      case ViewMode.Channels: {
        const channels = state.mediaLibrary.mediaLibrary.types[
          MediaType.Channel
        ].map(id => state.mediaLibrary.mediaLibrary.items[id]);
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards,
            channels
          )
        );
        break;
      }
      case ViewMode.Playlists: {
        const playlists = state.mediaLibrary.mediaLibrary.types[
          MediaType.Playlist
        ].map(id => state.mediaLibrary.mediaLibrary.items[id]);
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards,
            playlists
          )
        );
        break;
      }
      case ViewMode.Tags: {
        const tags = state.mediaLibrary.mediaLibrary.types[MediaType.Tag].map(
          id => state.mediaLibrary.mediaLibrary.items[id]
        );
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards,
            tags
          )
        );
        break;
      }
      case ViewMode.Media: {
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards
          )
        );
        break;
      }
      case ViewMode.MediLibrary: {
        const firstLevel = api.getFolderContent(-1, state.mediaLibrary.mediaLibrary);
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards,
            firstLevel
          )
        );
        break;
      }
      case ViewMode.Folder: {
        const firstLevel = api.getFolderContent(parentId, state.mediaLibrary.mediaLibrary);
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards,
            firstLevel
          )
        );
        break;
      }
      default: {
        dispatch(
          Actions.navigateTo(
            parentId,
            parentName,
            viewMode,
            mediaType,
            backwards
          )
        );
        dispatch(fetchChildren(parentId, viewMode));
      }
    }
  };
};

export const navigateBack = () => {
  return async (
    dispatch: Dispatch<any>,
    getState: () => { navigator: NavigatorState }
  ) => {
    const state = getState();
    if (state.navigator.path.length < 2) {
      return;
    }
    const parent = state.navigator.path[state.navigator.path.length - 2];
    dispatch(
      navigateTo(
        parent.id,
        parent.name,
        parent.viewMode,
        parent.mediaType,
        true
      )
    );
  };
};