import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { useStyles } from './progressIndicator.jss';

export const PrrogressIndicator: React.SFC<{size?: number}> = React.memo((props = {size:  60}) => {
  const classes = useStyles({});
  return <CircularProgress className={classes.loadingProgress} size={props.size} />;
});
