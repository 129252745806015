import { FastField, FieldProps } from 'formik';
import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { ImageProperties, Media } from '../../duck/types';
import { useStyles } from '../mediaInfo.jss';

export const Image: React.FunctionComponent<ImageProperties> = props => {
  const classes = useStyles({});
  return (
    <>
      <FastField
        name="properties.position"
        render={({ field, form }: FieldProps<Media>) => (
          <FormControl className={classes.field} fullWidth>
            <InputLabel id="position-select-label">Position</InputLabel>
            <Select
              labelId="position-select-label"
              id="position-select"
              {...field}
            >
              <MenuItem value={1}>Stretch</MenuItem>
              <MenuItem value={2}>Fill</MenuItem>
              <MenuItem value={3}>Fit</MenuItem>
              <MenuItem value={4}>Center</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <TextField
        className={classes.field}
        label="Size"
        fullWidth
        value={`${props.width}x${props.height}`}
        InputProps={{ readOnly: true }}
      />
    </>
  );
};
