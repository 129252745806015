import classNames from 'classnames';
import React from 'react';

import { Avatar } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';
import TagIcon from '@material-ui/icons/LocalOffer';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import ChannelIcon from '@material-ui/icons/Theaters';
import VideoIcon from '@material-ui/icons/Videocam';

import { MediaType } from '../commonTypes';
import { useStyles } from './mediaAvatar.jss';

interface MediaTypeToAvatarInfoMapper {
  [mediaType: number]: AvatarInfo;
}

const avatarMap: MediaTypeToAvatarInfoMapper = {
  [MediaType.Channel]: { className: 'channel', label: 'C', icon: <ChannelIcon/> },
  [MediaType.Playlist]: {
    className: 'playlist',
    label: 'P',
    icon: <PlaylistIcon />
  },
  [MediaType.EventPlaylist]: { className: 'playlist', label: 'E',
  icon: <PlaylistIcon /> },
  [MediaType.Tag]: { className: 'tag', label: 'T', icon: <TagIcon/> },
  [MediaType.Folder]: { className: 'folder', label: 'F', icon: <FolderIcon/> },
  [MediaType.DesignInstance]: { className: 'design', label: 'DI' },
  [MediaType.DesignTemplate]: { className: 'design', label: 'DT' },
  [MediaType.Image]: {
    className: 'media',
    label: 'I',
    icon: <ImageIcon />
  },
  [MediaType.Video]: {
    className: 'media',
    label: 'V',
    icon: <VideoIcon />
  }
};

export interface AvatarInfo {
  className: 'channel' | 'playlist' | 'folder' | 'tag' | 'media' | 'design';
  label: string;
  icon?: JSX.Element;
}

export const mediaTypeToAvatarInfo = (mediaType: MediaType): AvatarInfo => {
  if (avatarMap[mediaType] !== undefined) {
    return avatarMap[mediaType];
  } else {
    return { className: 'media', label: 'M' };
  }
};

export interface MediaAvatarProps {
  mediaType: MediaType;
}

export const MediaAvatar: React.FunctionComponent<MediaAvatarProps> = React.memo(
  props => {
    const classes = useStyles({});
    const avatarInfo = mediaTypeToAvatarInfo(props.mediaType);
    return (
      <Avatar
        className={classNames(classes.avatar, {
          [classes[avatarInfo.className]]: true
        })}
      >
        {avatarInfo.icon ? avatarInfo.icon : avatarInfo.label}
      </Avatar>
    );
  }
);
