import { MediaType } from '../../commonTypes';
import { ViewMode } from './types';

interface MediaTypeToViewModeMapper {
  [mediaType: number]: ViewMode;
}

interface MediaTypeToAvatarInfoMapper {
  [mediaType: number]: AvatarInfo;
}

const viewModeMap: MediaTypeToViewModeMapper = {
  [MediaType.Workgroup]: ViewMode.Workgroup,
  [MediaType.Channel]: ViewMode.Channel,
  [MediaType.Playlist]: ViewMode.Playlist,
  [MediaType.EventPlaylist]: ViewMode.Playlist,
  [MediaType.Tag]: ViewMode.Tag,
  [MediaType.Folder]: ViewMode.Folder
};

const avatarMap: MediaTypeToAvatarInfoMapper = {
  [MediaType.Channel]: { className: 'channel', label: 'C' },
  [MediaType.Playlist]: { className: 'playlist', label: 'P' },
  [MediaType.EventPlaylist]: { className: 'playlist', label: 'E' },
  [MediaType.Tag]: { className: 'tag', label: 'T' },
  [MediaType.Folder]: { className: 'folder', label: 'F' },
  [MediaType.DesignInstance]: { className: 'design', label: 'DI' },
  [MediaType.DesignTemplate]: { className: 'design', label: 'DT' },
  [MediaType.Image]: { className: 'media', label: 'I'},
  [MediaType.Video]: { className: 'media', label: 'V'},
};

export interface AvatarInfo {
  className: "channel" | "playlist" | "folder" | "tag" | "media" | "design";
  label: string;
}

export const mediaTypeToViewMode = (mediaType: MediaType): ViewMode => {
  if (viewModeMap[mediaType] !== undefined) {
    return viewModeMap[mediaType];
  } else {
    return ViewMode.Media;
  }
};

export const mediaTypeToAvatarInfo = (mediaType: MediaType): AvatarInfo => {
  if (avatarMap[mediaType] !== undefined) {
    return avatarMap[mediaType];
  } else {
    return { className: 'media', label: 'M' };
  }
};
