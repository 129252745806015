import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      overflowY: 'auto',
      padding: theme.spacing(1)
    },
    box: {
      padding: theme.spacing(2,3),
      marginBottom: theme.spacing(2)
    },
    thumbnail: {
      width: '100%',
      height: 'auto'
    },
    field: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  });
};

export const useStyles = makeStyles(styles);
