import { createAction } from 'typesafe-actions';

import { ActionTypes, Media, MediaInfo } from './types';

export const mediaInfoFetchRequest = createAction(
    ActionTypes.MEDIA_INFO_REQUEST,
    action => (mediaId: number) =>
      action({ mediaId })
  );
  
  export const mediaInfoFetchSuccess = createAction(
    ActionTypes.MEDIA_INFO_SUCCESS,
    action => (mediaInfo: MediaInfo, media: Media) =>
      action({ mediaInfo, media })
  );
  
  export const mediaInfoFetchError = createAction(
    ActionTypes.MEDIA_INFO_ERROR,
    action => (mediaId: number, error: string) =>
      action({ parentId: mediaId, error })
  );

  export const mediaUpdateRequest = createAction(
    ActionTypes.MEDIA_UPDATE_REQUEST,
    action => (media: Media) =>
      action({ media })
  );
  
  export const mediaUpdateSuccess = createAction(
    ActionTypes.MEDIA_UPDATE_SUCCESS,
    action => (mediaId: number) =>
      action({ mediaId })
  );
  
  export const mediaUpdateError = createAction(
    ActionTypes.MEDIA_UPDATE_ERROR,
    action => (mediaId: number, error: string) =>
      action({ parentId: mediaId, error })
  );