import { isBefore } from 'date-fns';
import { Form, Formik, FormikActions } from 'formik';
import React, { useEffect, useState } from 'react';

import { Backdrop, Button, CircularProgress, Fab, Paper, Tab, Tabs } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Create from '@material-ui/icons/Create';
import Photo from '@material-ui/icons/Photo';
import Schedule from '@material-ui/icons/Schedule';

import { FetchState, MediaType } from '../commonTypes';
import { PrrogressIndicator } from '../components/progressIndicator';
import { ChangedMFrameParams } from './duck/mFrameTypes';
import { Media } from './duck/types';
import { MediaFields } from './fields/mediaFields';
import { MediaInfo } from './info/mediaInfo';
import { useStyles } from './mediaEditor.jss';
import { MediaSchedule } from './scheduling/mediaSchedule';

export interface MediaEditorProps {
  mediaId: number;
  mediaType: MediaType;
  media?: Media;
  fetchState: FetchState;
  saveState: FetchState;
  onCancel: () => void;
}

export interface MediaEditorActions {
  fetchMediaInfo: (mediaId: number, mediaType: MediaType) => void;
  updateMedia: (media: Media, changedFields: ChangedMFrameParams[]) => void;
}

type Props = MediaEditorProps & MediaEditorActions;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: string;
}

const TabPanel: React.FunctionComponent<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export const MediaEditor: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { mediaId, fetchMediaInfo, mediaType } = props;
  useEffect(() => {
    fetchMediaInfo(mediaId, mediaType);
  }, [mediaId, fetchMediaInfo]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  if (props.fetchState.isFetching || !props.media) {
    return <PrrogressIndicator/>;
  }

  return (
    <Formik
      initialValues={props.media}
      onSubmit={(values: Media, actions: FormikActions<Media>) => {
        if(props.saveState.isFetching) {
          return;
        }
        if (isBefore(values.endDate, values.startDate)) {
          actions.setFieldError('startDate', 'Dates are wrong');
          actions.setFieldError('endDate', 'Dates are wrong');
        } else {
          const changedParams: ChangedMFrameParams[] = [];
          const currentFrame = values.mFrame;
          const initialFrame = props.media ? props.media.mFrame : undefined;
          if (currentFrame && initialFrame) {
            currentFrame.components.forEach((comp, compIndex) => {
              comp.params.forEach((currentParam, pIndex) => {
                const initialParam =
                  initialFrame.components[compIndex].params[pIndex];
                if (initialParam && currentParam.value !== initialParam.value) {
                  changedParams.push({
                    componentName: comp.name,
                    paramName: currentParam.name,
                    newValue: currentParam.value
                  });
                }
              });
            });
          }
          props.updateMedia(values, changedParams);
        }
      }}
      render={() => (
        <Form className={classes.formContainer}>
          <TabPanel value={tabIndex} index={0} className={classes.tabPanel}>
            {props.media && <MediaInfo media={props.media} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} className={classes.tabPanel}>
            <MediaSchedule />
          </TabPanel>
          {props.media && props.media.mFrame && (
            <TabPanel value={tabIndex} index={2} className={classes.tabPanel}>
              <MediaFields mFrame={props.media.mFrame} />
            </TabPanel>
          )}
          <Paper square elevation={4} className={classes.tabs}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab icon={<Photo />} label="PROPS" />
              <Tab icon={<Schedule />} label="SCHEDULE" />
              {props.media && props.media.mFrame && (
                <Tab icon={<Create />} label="FIELDS" />
              )}
            </Tabs>
          </Paper>
          <Backdrop open={props.saveState.isFetching}/>
          <Fab className={classes.saveFab} color="secondary" type='submit'>
            {props.saveState.isFetching ? (<CircularProgress color='inherit' size={20}/>) : (<Check />)}
          </Fab>
        </Form>
      )}
    ></Formik>
  );
};
