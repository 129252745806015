import React from 'react';
import {} from 'use-debounce';

import { Slider, Typography } from '@material-ui/core';

import { MFrameComponentRangedIntParam } from '../duck/mFrameTypes';
import { CustomFieldProps } from './mediaFields';

interface RangedIntProps
  extends Omit<CustomFieldProps<MFrameComponentRangedIntParam>, 'onChange'> {
  onChange: (value: number) => void;
}

export const RangedInt: React.FunctionComponent<RangedIntProps> = props => {
  const { onChange } = props;
  const [value, setValue] = React.useState(props.value);
  const onChangeCommited = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      onChange(value as number);
    },
    [onChange]
  );
  const innerOnChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setValue(value as number);
    },
    [setValue]
  );

  const marks = [
    {
      value: props.mFrameComponentParam.typeOptions.min,
      label: props.mFrameComponentParam.typeOptions.min
    },
    {
      value: props.mFrameComponentParam.typeOptions.max,
      label: props.mFrameComponentParam.typeOptions.max
    }
  ];
  return (
    <div className={props.className}>
      <Typography id="volume-slider" gutterBottom>
        {props.mFrameComponentParam.label['en-US'].value}
      </Typography>
      <Slider
        name={props.name}
        value={value}
        valueLabelDisplay="on"
        marks={marks}
        onChange={innerOnChange}
        onChangeCommitted={onChangeCommited}
        aria-labelledby="volume-slider"
        min={props.mFrameComponentParam.typeOptions.min}
        max={props.mFrameComponentParam.typeOptions.max}
      />
    </div>
  );
};
