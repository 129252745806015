import { MediaItem, MediaLibrary, MediaType } from '../../commonTypes';
import { MVisionData } from '../../mvisionIntegration';
import { ViewMode } from './types';

export const api = {
  fetchChildren: (
    parentId: number,
    parentType: ViewMode
  ): Promise<MediaItem[]> => {
    switch (parentType) {
      case ViewMode.Channel:
        return fetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/channel/${parentId}`, {
          method: 'GET',
          headers: {
            'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString()
          }
        }).then(response => response.json());
      case ViewMode.Playlist:
        return fetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/playlist/${parentId}`, {
          method: 'GET',
          headers: {
            'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString()
          }
        }).then(response => response.json());
      case ViewMode.Tag:
        return fetch(`${process.env.REACT_APP_ROOT_DOMAIN}v6/tag/${parentId}`, {
          method: 'GET',
          headers: {
            'MVision-Current-WorkgroupId': MVisionData.workgroupId.toString()
          }
        }).then(response => response.json());
      default:
        return new Promise<MediaItem[]>(() => []);
    }
  },
  getFolderContent: (
    folderId: number,
    mediaLibrary: MediaLibrary
  ): MediaItem[] => {
    const hiddenMediaTypes: MediaType[] = [
      MediaType.Playlist,
      MediaType.PlaylistLocalInputChild,
      MediaType.PlaylistLocalInputParent,
      MediaType.PlaylistOfFlash,
      MediaType.PlaylistOfZone,
      MediaType.AdvancedLocalInputPlaylistChild,
      MediaType.AdvancedLocalInputPlaylistParent,
      MediaType.EventPlaylist,
      MediaType.FirstValidPlaylist,
      MediaType.MusicPlaylist,
      MediaType.Channel,
      MediaType.Tag,
      MediaType.TagLocalInputChild,
      MediaType.TagLocalInputParent,
      MediaType.AdvancedLocalInputTagChild,
      MediaType.AdvancedLocalInputTagParent
    ];
    const firstLevel = Object.keys(mediaLibrary.items).reduce<MediaItem[]>(
      (a, id) => {
        const media = mediaLibrary.items[Number(id)];
        if (
          media.mediaFolderId === folderId &&
          hiddenMediaTypes.indexOf(media.mediaType) === -1
        ) {
          if (media.mediaType === MediaType.Folder) {
            a.splice(0, 0, media);
          } else {
            a.push(media);
          }
        }
        return a;
      },
      []
    );
    return firstLevel;
  }
};
