import { FetchState, MediaType } from '../../commonTypes';
import { MFrame } from './mFrameTypes';

export interface EditorState {
  mediaInfo: MediaInfo | undefined;
  media: Media | undefined;
  mediaFetchState: FetchState;
  mediaSaveState: FetchState;
}

export interface MediaInfo {
  id: number;
  workgroupId: number;
  userId: number;
  userName: string;
  userIdModification: number;
  mediaType: MediaType;
  name: string;
  description: string;
  loop: boolean;
  duration: number;
  isLocked: boolean;
  creationDate: string;
  modificationDate: string;
  isSharedWithMe: boolean;
  isSharedByMe: boolean;
  thumbnailSmall: string;
  thumbnailBig: string;
  contentId: number;
  contentFilePath: string;
  startDate: string;
  startD: Date;
  endDate: string;
  endD: Date;
  startTime: string;
  startT: Date;
  endTime: string;
  endT: Date;
  diffusionDays: number;
  properties: MediaProperties;
  tags: {
    tag: { id: number; name: string };
    addedByUserId: number;
    addedAt: string;
    addedByWorkgroupId: number;
  }[];
  flagColor: string;
  fileStatus: number;
  playlistItemsCount: number;
  isLocalInput: boolean;
  isAdvancedLocalInput: boolean;
  accessRights: [];
  calculatedAccessRight: number;
  mediaTypeRestrictions: [];
  maxNoOfElements: number;
  mediaFolderId: number;
  isFolder: boolean;
  mediaParentId: number;
}

export interface Media {
  id: number;
  type: MediaType;
  contentId: number;
  name: string;
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  diffusionDays: DiffusionDays;
  mFrame?: MFrame;
  thumbnailBig: string;
  description: string;
  creationDate: Date;
  userName: string;
  properties: MediaProperties;
}

export interface DiffusionDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export enum ActionTypes {
  MEDIA_INFO_REQUEST = 'MEDIA_INFO_REQUEST',
  MEDIA_INFO_SUCCESS = 'MEDIA_INFO_SUCCESS',
  MEDIA_INFO_ERROR = 'MEDIA_INFO_ERROR',
  MEDIA_UPDATE_REQUEST = 'MEDIA_UPDATE_REQUEST',
  MEDIA_UPDATE_SUCCESS = 'MEDIA_UPDATE_SUCCESS',
  MEDIA_UPDATE_ERROR = 'MEDIA_UPDATE_ERROR'
}

export interface BaseProperties {
  id: number;
  name: string;
}

export interface VideoProperties extends BaseProperties {
  keepAspectRatio: boolean;
  soundVolume: number;
  trueDuration?: number;
  width?: number;
  height?: number;
}

export interface AudioProperties extends BaseProperties {
  soundVolume: number;
  trueDuration?: number;
}

export interface ImageProperties extends BaseProperties {
  position: number;
  width?: number;
  height?: number;
}

export type MediaProperties =
  | VideoProperties
  | AudioProperties
  | ImageProperties;
