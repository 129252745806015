import { Field, FieldProps, FormikProps } from 'formik';
import React, { useCallback } from 'react';

import {
    Button, Checkbox, Divider, FormControlLabel, FormGroup, Typography
} from '@material-ui/core';

import { Media } from '../duck/types';
import { useStyles } from './daysForm.jss';

interface DayProps {
  day: string;
}

const DayFormItem = React.memo((props: DayProps) => {
  return (
    <Field
      name={`diffusionDays.${props.day}`}
      render={({ field, form }: FieldProps<Media>) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value}
              onChange={(e, checked) => form.setFieldValue(field.name, checked)}
            />
          }
          label={props.day}
        />
      )}
    />
  );
});

export const DaysForm: React.FunctionComponent<{}> = React.memo(props => {
  const classes = useStyles({});
  const onWeekdays = useCallback((form: FormikProps<Media>) => {
    form.setFieldValue('diffusionDays', {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false
    });
  }, []);
  const onWeekend = useCallback((form: FormikProps<Media>) => {
    form.setFieldValue('diffusionDays', {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: true,
      sunday: true
    });
  }, []);

  const onAllChange = useCallback(
    (form: FormikProps<Media>, checked: boolean) => {
      form.setFieldValue('diffusionDays', {
        monday: checked,
        tuesday: checked,
        wednesday: checked,
        thursday: checked,
        friday: checked,
        saturday: checked,
        sunday: checked
      });
    },
    []
  );

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          name="diffusionDays"
          render={({ field, form }: FieldProps<Media>) => {
            const atLeastOne = Object.keys(field.value).reduce<boolean>(
              (x, day) => x || field.value[day],
              false
            );
            const all = Object.keys(field.value).reduce<boolean>(
              (x, day) => x && field.value[day],
              true
            );
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={atLeastOne && !all}
                    onChange={(_, checked) => onAllChange(form, checked)}
                    checked={all}
                  />
                }
                label={<Typography variant="button">All</Typography>}
              />
            );
          }}
        />
        <Field
          name="diffusionDays"
          render={({ field, form }: FieldProps<Media>) => (
            <Button
              className={classes.weekdaysButton}
              variant="text"
              size="small"
              onClick={() => onWeekdays(form)}
            >
              Weekdays
            </Button>
          )}
        />
        <Field
          name="diffusionDays"
          render={({ field, form }: FieldProps<Media>) => (
            <Button
              className={classes.weekendButton}
              variant="text"
              size="small"
              onClick={() => onWeekend(form)}
            >
              Weekend
            </Button>
          )}
        />
      </div>
      <Divider />
      <FormGroup>
        <DayFormItem day="monday" />
        <DayFormItem day="tuesday" />
        <DayFormItem day="wednesday" />
        <DayFormItem day="thursday" />
        <DayFormItem day="friday" />
        <DayFormItem day="saturday" />
        <DayFormItem day="sunday" />
      </FormGroup>
    </div>
  );
});
