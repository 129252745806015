export interface MediaItem {
  id: number;
  contentId: number;
  name: string;
  mediaType: MediaType;
  mediaFolderId: number;
}

export interface MediaLibrary {
  items: { [id: number]: MediaItem };
  types: { [mediaType: number]: number[] };
}

export enum MediaType {
    None = 0,
    Video = 1,
    Flash = 2,
    Capture = 3,
    TunerTv = 4,
    Image = 5,
    WebPage = 6,
    Scroller = 7,
    Text = 8,
    PowerPoint = 9,
    Audio = 10,
    Rss = 11,
    WmpPlayer = 12,
    Playlist = 13,
    Channel = 14,
    DesignTemplate = 15,
    Tag = 16,
    DesignInstance = 17,
    ScreenLayout = 18,
    PlaylistOfZone = 19,
    FlashEditable = 20,
    PlaylistOfFlash = 21,
    DateTime = 22,
    Color = 23,
    Enum = 24,
    Number = 25,
    PlaylistLocalInputParent = 26,
    FlashEditableTemplate = 27,
    TagLocalInputParent = 28,
    TagLocalInputChild = 29,
    PlaylistLocalInputChild = 30,
    TvPassThru = 31,
    EventPlaylist = 32,
    HtmlTemplate = 33,
    HtmlInstance = 34,
    Folder = 35,
    Feed = 36,
    AdvancedLocalInputPlaylistParent = 37,
    AdvancedLocalInputPlaylistChild = 38,
    AdvancedLocalInputTagParent = 39,
    AdvancedLocalInputTagChild = 40,
    NativeView = 41,
    MusicPlaylist = 42,
    MusicMix = 43,
    MusicProgram = 44,
    FirstValidPlaylist = 45,
    Silence = 46,
    PlayerStream = 47,
    Workgroup = 999
}

export const MediaTypeLabels: {[mediaType: number]: string} = {
  [MediaType.None]: "None",
  [MediaType.Video]: "Video",
  [MediaType.Flash]: "Flash",
  [MediaType.Capture]: "Capture",
  [MediaType.TunerTv]: "TunerTv",
  [MediaType.Image]: "Image",
  [MediaType.WebPage]: "WebPage",
  [MediaType.Scroller]: "Scroller",
  [MediaType.Text]: "Text",
  [MediaType.PowerPoint]: "PowerPoint",
  [MediaType.Audio]: "Audio",
  [MediaType.Rss]: "Rss",
  [MediaType.WmpPlayer]: "WmpPlayer",
  [MediaType.Playlist]: "Playlist",
  [MediaType.Channel]: "Channel",
  [MediaType.DesignTemplate]: "Design template",
  [MediaType.Tag]: "Tag",
  [MediaType.DesignInstance]: "Design instance",
  [MediaType.ScreenLayout]: "ScreenLayout",
  [MediaType.PlaylistOfZone]: "Playlist of zone",
  [MediaType.FlashEditable]: "Flash editable",
  [MediaType.PlaylistOfFlash]: "Playlist of flash",
  [MediaType.DateTime]: "Datetime",
  [MediaType.Color]: "Color",
  [MediaType.Enum]: "Enum",
  [MediaType.Number]: "Number",
  [MediaType.PlaylistLocalInputParent]: "PlaylistLocalInputParent",
  [MediaType.FlashEditableTemplate]: "FlashEditableTemplate",
  [MediaType.TagLocalInputParent]: "TagLocalInputParent",
  [MediaType.TagLocalInputChild]: "TagLocalInputChild",
  [MediaType.PlaylistLocalInputChild]: "PlaylistLocalInputChild",
  [MediaType.TvPassThru]: "TvPassThru",
  [MediaType.EventPlaylist]: "Event playlist",
  [MediaType.HtmlTemplate]: "Html template",
  [MediaType.HtmlInstance]: "Html instance",
  [MediaType.Folder]: "Folder",
  [MediaType.Feed]: "Feed",
  [MediaType.AdvancedLocalInputPlaylistParent]: "ALIS Playlist parent",
  [MediaType.AdvancedLocalInputPlaylistChild]: "ALIS Playlist child",
  [MediaType.AdvancedLocalInputTagParent]: "ALIS Tag parent",
  [MediaType.AdvancedLocalInputTagChild]: "ALIS Tag child",
  [MediaType.NativeView]: "NativeVview",
  [MediaType.MusicPlaylist]: "Music playlist",
  [MediaType.MusicMix]: "Music mix",
  [MediaType.MusicProgram]: "Music program",
  [MediaType.FirstValidPlaylist]: "First valid playlist",
  [MediaType.Silence]: "Silence",
  [MediaType.PlayerStream]: "Player stream",
  [MediaType.Workgroup]: "Workgroup",
}

export interface FetchState {
  isFetching: boolean;
  fetchError: string;
  fetchCompleted: boolean;
  id: number;
}
