import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    weekdaysButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    weekendButton: {
      marginRight: theme.spacing(1),
    }
  });
};

export const useStyles = makeStyles(styles);
