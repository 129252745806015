import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
  },
  tabPanel: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: 'auto',
    paddingBottom: 96
  },
  tabs: {
    zIndex: 1100
  },
  saveFab: {
    position: 'absolute',
    bottom: theme.spacing(11),
    right: theme.spacing(2),
  }
}));