import { createMuiTheme, Theme } from '@material-ui/core';

export const moodTheme: Theme = createMuiTheme({
  palette: {
    primary: { main: "#232931" }, // Mood black
    secondary: { main: "#ce102d" } // Mood red
  },
  typography: {
  }
});

export const moodThemeDark: Theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#232931" }, // Mood black
    secondary: { main: "#ce102d" } // Mood red
  },
  typography: {
  }
});
