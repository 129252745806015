import React from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { moodTheme } from './App.jss';
import { NavigatorContainer } from './navigator';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';

const generateClassName = createGenerateClassName({
  productionPrefix: 'mme',
  disableGlobal: true
});

const App: React.FC = () => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodTheme}>
        <NavigatorContainer />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
