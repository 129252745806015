import React from 'react';

import {
    FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
    MFrameComponentButtonGroupParam, MFrameComponentSelectImagesParam, MFrameComponentSelectParam
} from '../duck/mFrameTypes';
import { CustomFieldProps } from './mediaFields';

interface SelectFieldProps extends CustomFieldProps<
| MFrameComponentSelectParam
| MFrameComponentSelectImagesParam
| MFrameComponentButtonGroupParam
> {
  customOnChange: (newValue: string | string[]) => void;
}

export const SelectField: React.FunctionComponent<SelectFieldProps> = props => {
  const { mFrameComponentParam } = props;
  const typeOptions = mFrameComponentParam.typeOptions; // need to use this as its own variable since type discrimination does not work on nested object properties in Typescript
  switch (typeOptions.renderType) {
    case 'radio':
      return (
        <FormControl fullWidth className={props.className}>
          <FormLabel>
            {props.mFrameComponentParam.label['en-US'].value}
          </FormLabel>
          <RadioGroup
            value={props.value}
            onChange={props.onChange}
            name={props.name}
          >
            {typeOptions.values.map(v => (
              <FormControlLabel
                value={v}
                control={<Radio />}
                label={v}
                key={v}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    case 'images':
      return (
        <FormControl className={props.className} fullWidth>
          <InputLabel id={`${props.name}-select-label`}>
            {props.mFrameComponentParam.label['en-US'].value}
          </InputLabel>
          <Select
            labelId={`${props.name}-select-label`}
            id={`${props.name}-select`}
            onChange={props.onChange}
            name={props.name}
            value={props.value}
          >
            {typeOptions.values.map(v => (
              <MenuItem key={v.value} value={v.value}>
                <img src={v.image} alt={v.name} />
                {v.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'btngroup':
      return (
        <ToggleButtonGroup
          color="secondary"
          exclusive={true}
          value={props.value}
          aria-label={props.mFrameComponentParam.label['en-US'].value}
        >
          {typeOptions.values.map(v => (
            <ToggleButton  value={v} key={v} onClick={(e) => {
              props.customOnChange(v);
            }}>
              <div className={typeOptions.icons[v]}/>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    case undefined:
      return (
        <FormControl className={props.className} fullWidth>
          <InputLabel id={`${props.name}-select-label`}>
            {props.mFrameComponentParam.label['en-US'].value}
          </InputLabel>
          <Select
            labelId={`${props.name}-select-label`}
            id={`${props.name}-select`}
            onChange={props.onChange}
            name={props.name}
            value={props.value}
          >
            {(typeOptions.values as string[]).map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return <div />;
  }
};
