import { FastField, FieldProps } from 'formik';
import React from 'react';

import {
    Divider, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextField, Typography,
    withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MediaType } from '../../commonTypes';
import {
    MFrame, MFrameComponent, MFrameComponentParam, MFrameComponentParamBase
} from '../duck/mFrameTypes';
import { Media } from '../duck/types';
import { ArrayField } from './arrayField';
import { BoolField } from './boolField';
import { ColorField } from './colorField';
import { useStyles } from './mediaFields.jss';
import { MediaReferenceFieldContainer } from './mediaReferenceFieldContainer';
import { RangedInt } from './rangedInt';
import { SelectField } from './selectField';

export interface MediaFieldsProps {
  mFrame: MFrame;
}

export interface CustomFieldProps<T extends MFrameComponentParamBase> {
  mFrameComponentParam: T;
  value: any;
  name: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  className: string;
}

const locale = 'en-US';
const mediaReferenceFieldTypes: MediaType[] = [
  MediaType.Tag,
  MediaType.Playlist,
  MediaType.Image,
  MediaType.Video
];

const imageReferenceFieldTypes: MediaType[] = [MediaType.Image];

const generateField = (
  mFrameComponentParam: MFrameComponentParam,
  fieldProps: FieldProps<Media>,
  fieldClass: string
) => {
  switch (mFrameComponentParam.type) {
    case 'string':
      return (
        <TextField
          fullWidth
          className={fieldClass}
          label={mFrameComponentParam.label[locale].value}
          {...fieldProps.field}
        />
      );
    case 'int':
      return (
        <TextField
          fullWidth
          type={'number'}
          className={fieldClass}
          label={mFrameComponentParam.label[locale].value}
          {...fieldProps.field}
          onChange={e => {
            fieldProps.field.onChange(e);
          }}
        />
      );
    case 'bool':
      return (
        <BoolField
          className={fieldClass}
          mFrameComponentParam={mFrameComponentParam}
          {...fieldProps.field}
        />
      );
    case 'color':
      return (
        <ColorField
          className={fieldClass}
          mFrameComponentParam={mFrameComponentParam}
          {...fieldProps.field}
        />
      );
    case 'select':
      return (
        <SelectField
          className={fieldClass}
          mFrameComponentParam={mFrameComponentParam}
          customOnChange={newValue => fieldProps.form.setFieldValue(fieldProps.field.name, newValue)}
          {...fieldProps.field}
        />
      );
    case 'array':
      return (
        <ArrayField
          label={mFrameComponentParam.label[locale].value}
          value={fieldProps.field.value}
          onChange={(newValue: string[]) =>
            fieldProps.form.setFieldValue(fieldProps.field.name, newValue)
          }
        />
      );
    case 'rangedInt':
      return (
        <RangedInt
          className={fieldClass}
          mFrameComponentParam={mFrameComponentParam}
          {...fieldProps.field}
          onChange={(value: number) =>
            fieldProps.form.setFieldValue(fieldProps.field.name, value)
          }
        />
      );
    case 'mediaReference':
      return (
        <MediaReferenceFieldContainer
          mediaTypes={mediaReferenceFieldTypes}
          value={fieldProps.field.value}
          label={mFrameComponentParam.label[locale].value}
          onChange={(newValue: number[]) =>
            fieldProps.form.setFieldValue(fieldProps.field.name, newValue)
          }
        />
      );
    case 'imageReference':
      return (
        <MediaReferenceFieldContainer
          mediaTypes={imageReferenceFieldTypes}
          value={fieldProps.field.value}
          label={mFrameComponentParam.label[locale].value}
          onChange={(newValue: number[]) =>
            fieldProps.form.setFieldValue(fieldProps.field.name, newValue)
          }
        />
      );
    default:
      return (
        <div>{`${mFrameComponentParam.type}|${mFrameComponentParam.name}`}</div>
      );
  }
};

interface MFrameParamsGroup {
  label: string;
  params: {
    index: number; // the index in the component is important in order to map the field in formik values
    param: MFrameComponentParam;
  }[];
}

const groupParamsBySeparators = (mFrameComponent: MFrameComponent) => {
  const defaultGroup: MFrameParamsGroup = {
    label: '',
    params: []
  };
  const initialGroupping = {
    groups: [defaultGroup],
    currentGroup: defaultGroup
  };
  const groupping = mFrameComponent.params.reduce<{
    groups: MFrameParamsGroup[];
    currentGroup: MFrameParamsGroup;
  }>((o, param, index) => {
    if (param.type === 'separator') {
      o.currentGroup = { label: param.label[locale].value, params: [] };
      o.groups.push(o.currentGroup);
    } else {
      o.currentGroup.params.push({ index, param });
    }
    return o;
  }, initialGroupping);
  return groupping.groups.filter(g => g.params.length > 0);
};

const mapParamGroupToFields = (
  componentIndex: number,
  fieldClass: string,
  group: MFrameParamsGroup
) =>
  group.params.map(paramInfo => {
    const fieldName = `mFrame.components[${componentIndex}].params[${paramInfo.index}].value`;

    return (
      <FastField
        key={fieldName}
        name={fieldName}
        render={(fieldProps: FieldProps<Media>) =>
          generateField(paramInfo.param, fieldProps, fieldClass)
        }
      />
    );
  });

const generateFields = (
  mFrameComponent: MFrameComponent,
  componentIndex: number,
  fieldClass: string,
  componentClass: string,
) => {
  var paramGroups = groupParamsBySeparators(mFrameComponent);
  if (paramGroups.length === 1) {
    return mapParamGroupToFields(componentIndex, fieldClass, paramGroups[0]);
  }
  return paramGroups.map(group => {
    if (group.label && group.params.length > 0) {
      return (
        <InnerExpansionPanel key={group.label} TransitionProps={{ unmountOnExit: true }}>
          <InnerExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">{group.label}</Typography>
          </InnerExpansionPanelSummary>
          <ExpansionPanelDetails className={componentClass}>
            {mapParamGroupToFields(componentIndex, fieldClass, group)}
          </ExpansionPanelDetails>
        </InnerExpansionPanel>
      );
    } else return mapParamGroupToFields(componentIndex, fieldClass, group);
  });
};

const generateComponents = (
  mFrame: MFrame,
  componentClass: string,
  fieldClass: string
) => {
  return mFrame.components.map((comp, index) => (
    <ExpansionPanel square key={comp.name} TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body1">{comp.label[locale].value}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={componentClass}>
        {generateFields(comp, index, fieldClass, componentClass)}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
};

const InnerExpansionPanel = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    marginLeft: '-24px',
    marginRight: '-24px',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginLeft: '-24px',
    marginRight: '-24px',
    },
  },
  expanded: {},
})(ExpansionPanel);

const InnerExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  expanded: {},
})(ExpansionPanelSummary);

export const MediaFields: React.FunctionComponent<MediaFieldsProps> = props => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {generateComponents(props.mFrame, classes.component, classes.field)}
    </div>
  );
};
