import * as dateFns from 'date-fns';

export const parseTimeSpan = (timeSpan: string) => {
  const timeSpanParts = timeSpan.split(':');
  if (timeSpanParts.length !== 3) {
    throw new Error('TimeSpan format incorrect. The desired format is HH:mm:ss');
  }
  const hours = Number(timeSpanParts[0]);
  const minutes = Number(timeSpanParts[1]);
  const seconds = Number(timeSpanParts[2]);

  const startOfToday = dateFns.startOfDay(new Date());

  let date = dateFns.addHours(startOfToday, hours);
  date = dateFns.addMinutes(date, minutes);
  date = dateFns.addSeconds(date, seconds);
  return date;
};

export const parseDate = (date: string) => dateFns.parseISO(date)

// type DayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
// export const daysOfTheWeek = (format: string = 'dd', sundayIsFirstDay: boolean = true): Array<{
//   label: string,
//   day: DayNumber
// }> => {
//   const weekStartsOn = sundayIsFirstDay ? 0 : 1;
//   const today = new Date();
//   const startOfWeek = dateFns.startOfWeek(today, { weekStartsOn });
//   const days = [];

//   for (let i = 0; i < 7; i++) {
//     const dayDate = dateFns.addDays(startOfWeek, i);
//     const label = dateFns.format(dayDate, format, {
//       locale: i18next.language,
//     });
//     const day = dateFns.getDay(dayDate) as DayNumber;
//     days.push({ label, day });
//   }
//   return days;
// };

// const dayToPowerMap = {
//   0: 1,
//   1: 64,
//   2: 32,
//   3: 16,
//   4: 8,
//   5: 4,
//   6: 2
// }

// export const selectedDaysFromDiffusion = (diffusionDays: number, format: string = 'dd', sundayIsFirstDay: boolean = true) => {
//   return daysOfTheWeek(format, sundayIsFirstDay).map(d => ({
//     label: d.label,
//     day: d.day,
//     selected: (diffusionDays & dayToPowerMap[d.day]) !== 0
//   }));
// }



