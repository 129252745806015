import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      margin: theme.spacing(1)
    },
    component: {
      flexDirection: 'column'
    },
    field: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3)
      }
    },
    listItem: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden'
    }
  });
};

export const useStyles = makeStyles(styles);
