import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(2)
    },
    cardAction: {
      padding: theme.spacing(2),
    },
    cardLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardBadge: {
        display: 'flex',
        fledDirection: 'row',
        alignItems: 'center'
    },
    cardAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    cardLabel: {
      marginTop: theme.spacing(2)
    }

  }),
);