import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0
  },
  title: {
    flexGrow: 1
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loadingProgress: {
    top: '50%',
    position: 'absolute',
    alignSelf: 'center'
  },
}));